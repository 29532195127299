// Variables
// --------------------------

$FontAwesomePath:    "/font";
$FontAwesomeVersion: "vexqt6";
$borderColor:        #eee;
$iconMuted:          #eee;
$iconLight:          #fff;
$iconDark:           #333;

// --------------------------

// the font itself is now served behind cache headers (see /index.pug)
@import "font-awesome/icons";
@import "font-awesome/core";
@import "font-awesome/bootstrap";
@import "font-awesome/extras";
@import "font-awesome/style";
