$icon-fire-extinguisher: "\e910";
$icon-arrow-down: "\e979";
$icon-arrow-left: "\e97a";
$icon-arrow-right: "\e97b";
$icon-arrow-up: "\e97c";
$icon-dot-single: "\e978";
$icon-cog: "\e95f";
$icon-cogs: "\e95f";
$icon-note2: "\e90f";
$icon-spotman: "\e90c";
$icon-voting: "\e936";
$icon-qna: "\e937";
$icon-surveys: "\e938";
$icon-note: "\e939";
$icon-sponsors: "\e93a";
$icon-more-events: "\e93b";
$icon-warning: "\e801";
$icon-warning-sign: "\e801";
$icon-chevron-down: "\e802";
$icon-caret-down: "\e802";
$icon-chevron-left: "\e803";
$icon-caret-left: "\e803";
$icon-chevron-right: "\e804";
$icon-caret-right: "\e804";
$icon-chevron-up: "\e805";
$icon-caret-up: "\e805";
$icon-cloud: "\e806";
$icon-controller-jump-to-start: "\e93c";
$icon-controller-next: "\e93d";
$icon-controller-paus: "\e93e";
$icon-controller-play: "\e93f";
$icon-controller-record: "\e940";
$icon-controller-stop: "\e941";
$icon-dots-three-horizontal: "\e807";
$icon-ellipsis-horizontal: "\e807";
$icon-dots-three-vertical: "\e808";
$icon-download: "\e946";
$icon-resize-100: "\e95b";
$icon-resize-small: "\e95b";
$icon-resize-full-screen: "\e95c";
$icon-fullscreen: "\e95c";
$icon-select-arrows: "\e974";
$icon-trash: "\e809";
$icon-triangle-down: "\e80a";
$icon-sort-down: "\e80a";
$icon-triangle-left: "\e80b";
$icon-triangle-right: "\e80c";
$icon-triangle-up: "\e80d";
$icon-sort-up: "\e80d";
$icon-upload: "\e96e";
$icon-upload-to-cloud: "\e96e";
$icon-upload-alt: "\e96e";
$icon-save: "\e96e";
$icon-address: "\e80e";
$icon-adjust: "\e975";
$icon-aircraft: "\e80f";
$icon-archive: "\e810";
$icon-area-graph: "\e811";
$icon-attachment: "\e812";
$icon-back: "\e95d";
$icon-bar-graph: "\e95e";
$icon-bar-chart: "\e95e";
$icon-bell: "\e813";
$icon-blackboard: "\e814";
$icon-block: "\e815";
$icon-ban-circle: "\e815";
$icon-book: "\e908";
$icon-box: "\e816";
$icon-briefcase: "\e817";
$icon-browser: "\e942";
$icon-list-alt: "\e942";
$icon-brush: "\e943";
$icon-bucket: "\e909";
$icon-cake: "\e90d";
$icon-calendar: "\e818";
$icon-camera: "\e819";
$icon-ccw: "\e81a";
$icon-chat: "\e81b";
$icon-comments: "\e81b";
$icon-comments-alt: "\e81b";
$icon-comment: "\e81b";
$icon-comment-alt: "\e81b";
$icon-check: "\e81c";
$icon-ok: "\e81c";
$icon-circle-with-cross: "\e81d";
$icon-remove: "\e81d";
$icon-remove-sign: "\e81d";
$icon-circle-with-minus: "\e81e";
$icon-circle-with-plus: "\e81f";
$icon-circle: "\e976";
$icon-circle-blank: "\e976";
$icon-clapperboard: "\e90e";
$icon-colours: "\e911";
$icon-compass: "\e912";
$icon-copy: "\e96f";
$icon-credit-card: "\e944";
$icon-credit: "\e970";
$icon-cross: "\e821";
$icon-cw: "\e822";
$icon-cycle: "\e823";
$icon-refresh: "\e823";
$icon-database: "\e900";
$icon-direction: "\e824";
$icon-file: "\e960";
$icon-documents: "\e901";
$icon-drop: "\e945";
$icon-tint: "\e945";
$icon-edit: "\e825";
$icon-email: "\e913";
$icon-emoji-happy: "\e826";
$icon-emoji-neutral: "\e827";
$icon-emoji-sad: "\e828";
$icon-erase: "\e829";
$icon-eye: "\e82a";
$icon-eye-open: "\e82a";
$icon-feather: "\e61f";
$icon-flag: "\e914";
$icon-flash: "\e82b";
$icon-flashlight: "\e915";
$icon-folder-images: "\e916";
$icon-folder-music: "\e917";
$icon-folder-video: "\e918";
$icon-folder: "\e919";
$icon-folder-open: "\e919";
$icon-forward: "\e82c";
$icon-redo: "\e82c";
$icon-repeat: "\e82c";
$icon-funnel: "\e82d";
$icon-filter: "\e82d";
$icon-game-controller: "\e82e";
$icon-gauge: "\e902";
$icon-globe: "\e903";
$icon-graduation-cap: "\e82f";
$icon-grid: "\e949";
$icon-th-large: "\e949";
$icon-hair-cross: "\e830";
$icon-hand: "\e831";
$icon-heart-outlined: "\e832";
$icon-heart: "\e833";
$icon-help-with-circle: "\e834";
$icon-question-sign: "\e834";
$icon-help: "\e947";
$icon-home: "\e835";
$icon-hour-glass: "\e836";
$icon-image: "\e948";
$icon-picture: "\e948";
$icon-images: "\e837";
$icon-infinity: "\e91a";
$icon-info-with-circle: "\e838";
$icon-info-sign: "\e838";
$icon-info: "\e91b";
$icon-key: "\e839";
$icon-keyboard: "\e91c";
$icon-lab-flask: "\e91d";
$icon-language: "\e95a";
$icon-laptop: "\e91e";
$icon-leaf: "\e91f";
$icon-lifebuoy: "\e83a";
$icon-light-bulb: "\e83b";
$icon-lightbulb: "\e83b";
$icon-light-down: "\e961";
$icon-light-up: "\e94a";
$icon-line-graph: "\e94b";
$icon-link: "\e83c";
$icon-list: "\e94c";
$icon-list-ul: "\e94c";
$icon-list-li: "\e94c";
$icon-location-pin: "\e83d";
$icon-location: "\e83e";
$icon-lock-open: "\e83f";
$icon-lock: "\e840";
$icon-log-out: "\e94d";
$icon-login: "\e94e";
$icon-magnet: "\e962";
$icon-magnifying-glass: "\e904";
$icon-mail: "\e841";
$icon-envelope: "\e841";
$icon-envelope-alt: "\e841";
$icon-man: "\e842";
$icon-map: "\e843";
$icon-mask: "\e963";
$icon-medal: "\e964";
$icon-megaphone: "\e844";
$icon-bullhorn: "\e844";
$icon-menu: "\e845";
$icon-reorder: "\e845";
$icon-message: "\e846";
$icon-mic: "\e847";
$icon-microphone: "\e847";
$icon-minus: "\e848";
$icon-mobile-phone: "\e965";
$icon-modern-mic: "\e920";
$icon-mouse: "\e921";
$icon-network: "\e94f";
$icon-new-message: "\e950";
$icon-new: "\e849";
$icon-news: "\e84a";
$icon-notification: "\e971";
$icon-old-mobile: "\e966";
$icon-old-phone: "\e967";
$icon-palette: "\e84b";
$icon-paper-plane: "\e84c";
$icon-pencil: "\e84d";
$icon-phone: "\e84e";
$icon-pie-chart: "\e968";
$icon-pin: "\e922";
$icon-pushpin: "\e922";
$icon-plus: "\e84f";
$icon-popup: "\e951";
$icon-power-plug: "\e952";
$icon-price-tag: "\e923";
$icon-print: "\e924";
$icon-progress-empty: "\e969";
$icon-progress-full: "\e96a";
$icon-progress-one: "\e96b";
$icon-progress-two: "\e96c";
$icon-quote: "\e972";
$icon-reply-all: "\e850";
$icon-reply: "\e851";
$icon-undo: "\e851";
$icon-rocket: "\e925";
$icon-round-brush: "\e926";
$icon-feed: "\e852";
$icon-ruler: "\e927";
$icon-scissors: "\e928";
$icon-share: "\e853";
$icon-shield: "\e953";
$icon-shop: "\e854";
$icon-shopping-basket: "\e954";
$icon-shopping-cart: "\e929";
$icon-shuffle: "\e955";
$icon-signal: "\e92a";
$icon-sound-mix: "\e92b";
$icon-sports-club: "\e92c";
$icon-table: "\e973";
$icon-star-outlined: "\e855";
$icon-star: "\e856";
$icon-stopwatch: "\e92d";
$icon-suitcase: "\e92e";
$icon-swap: "\e956";
$icon-switch: "\e957";
$icon-off: "\e957";
$icon-tablet: "\e96d";
$icon-thermometer: "\e92f";
$icon-thumbs-down: "\e857";
$icon-thumbs-up: "\e858";
$icon-thunder-cloud: "\e859";
$icon-ticket: "\e930";
$icon-time-slot: "\e958";
$icon-tools: "\e90a";
$icon-wrench: "\e90a";
$icon-trophy: "\e85a";
$icon-tv: "\e977";
$icon-desktop: "\e977";
$icon-typing: "\e959";
$icon-user: "\e85b";
$icon-users: "\e85c";
$icon-group: "\e85c";
$icon-v-card: "\e85d";
$icon-video: "\e931";
$icon-vinyl: "\e90b";
$icon-voicemail: "\e932";
$icon-wallet: "\e933";
$icon-facebook: "\e85e";
$icon-flickr: "\e905";
$icon-google: "\e934";
$icon-instagram: "\e935";
$icon-linkedin-with-circle: "\e85f";
$icon-twitter: "\e860";
$icon-youtube: "\e906";
$icon-eye-with-line: "\e97d";
$icon-eye-close: "\e97d";
$icon-qrcode: "\f029";
$icon-font: "\f031";
$icon-bold: "\f032";
$icon-italic: "\f033";
$icon-align-left: "\f036";
$icon-align-center: "\f037";
$icon-align-right: "\f038";
$icon-align-justify: "\f039";
$icon-editor-list-ul: "\f0ca";
$icon-editor-list-ol: "\f0cb";
$icon-list-ol: "\f0cb";
$icon-strikethrough: "\f0cc";
$icon-underline: "\f0cd";
$icon-superscript: "\f12b";
$icon-subscript: "\f12c";
$icon-puzzle-piece: "\f12e";
$icon-loader: "\e907";
$icon-spinner: "\e907";
