// Third party plugins and overrides
// ---------------------------------

// -- Bootstrap 2
@import "third-party/bootstrap";

// -- Fontawesome
@import "third-party/font-awesome";

// -- JS Tag
@import "third-party/jsTag";
@import "third-party/jsTag-overrides";

// -- Rome
@import "third-party/rome";

// -------------------
// - Legacy LESS build
// -------------------

// SpotMe Brand Colours
$spotme-orange:     #fb4f15 !default;
$spotme-grey:       #4b4b4b !default;
$spotme-cyan:       #19c4ca !default;
$spotme-yellow:     #fdb62a !default;
$spotme-green:      #9bcf64 !default;

@import "bstg-typeahead";
@import "bstg-text-angular";
@import "bstg-views";

// -------------------
// - Legacy SASS build
// -------------------

@import "bstg-loading-throbber-ng";
@import "bstg-spotlet-loading-throbber";

@import "app-branding";


// AngularJS components manifest
// -----------------------------

@import "angular/components/components";
