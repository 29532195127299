/*!
 * Bootstrap v2.0.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */

// Core variables and mixins
@import "bootstrap/vars"; // Modify this for custom colors, font-sizes, etc
@import "bootstrap/mixins";

// Grid system and page structure
@import "bootstrap/grid";
@import "bootstrap/layouts";

// Components: common
@import "bootstrap/component-animations";

// Components: Nav
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";

// Utility classes
@import "bootstrap/utilities"; // Has to be last to override when necessary
